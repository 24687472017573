<template>
  <base-layout-two :page-title="user.fullname" :page-default-back-link="`/users/${$route.params.id}`"
    content-class="backround-white">
    <section class="ion-padding">
      <h1>Reservations</h1>
      <ion-button class="regular-button" expand="block" router-link="/reservations/create"
        v-if="authUser.role === 'admin' || authUser.role === 'manager'">
        New Reservation
      </ion-button>
    </section>

    <section class="ion-padding">
      <ion-list>
        <reservation-list-item v-for="reservation in reservations" :key="reservation.id" :reservation="reservation"
          :router-link="`/reservations/${reservation.id}`"></reservation-list-item>
      </ion-list>
    </section>
  </base-layout-two>
</template>

<script>
import { IonList, IonButton } from "@ionic/vue";
import ReservationListItem from "@/components/reservations/ReservationListItem.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    IonList,
    IonButton,
    ReservationListItem,
  },
  computed: {
    ...mapGetters(["authUser"]),
  },
  data() {
    return {
      user: {},
      reservations: [],
    };
  },
  ionViewWillEnter() {
    this.fetchReservations();
  },
  methods: {
    async fetchReservations() {
      await this.axios
        .get(
          `${process.env.VUE_APP_API}/reservations?user_id=${this.$route.params.id}&cancelled=1`
        )
        .then((response) => {
          this.reservations = response.data.reservations;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>